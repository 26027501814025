import React, { useState, useEffect } from 'react';
import flightImg from '../assets/images/flight-1.png';
import { FiCheckCircle } from '../assets/icons/vander'
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create an axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 100000, // Set a timeout for requests (10 seconds in this example)
});

const WaitlistModal = () => {
  const [isOpen, setIsOpen] = useState(!Cookies.get('Waitlist')); // Modal is open on page load
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission
  const [currentDomain, setCurrentDomain] = useState('');

  // Close the modal when the form is submitted
  useEffect(() => {
    // Get the current domain name
    const currentDomain = window.location.hostname;
    console.log('Current Domain:', currentDomain);
    setCurrentDomain(currentDomain);
  }, []);

  // Close the modal when the form is submitted
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    addWaitList();
  };


  const addWaitList = async () => {
    try {
      const response = await axiosInstance.post(BASE_URL + '/api/addToWaitingList', {
        email: email, 
        domain: currentDomain
      });
      Cookies.set('Waitlist', JSON.stringify(response.data));
      toast.success('Waitlist added successfully!');

      return { data: response, error: null };
    } catch (error) {
      console.error('POST request error:', error);
      return { data: null, error: error.displayMessage, status: error.status };
    }
  };

  const handleClose = () => {
    setIsOpen(false); // Close modal when close icon is clicked
  };

  return (
    <>
      {isOpen && !isSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center z-999" style={{backgroundColor:'rgba(0,0,0,0.6)'}}>
          <div className="bg-white rounded-lg overflow-hidden max-w-lg w-full relative">
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 text-white focus:outline-none z-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className='bg-slate-800 py-20 relative z-1' >
              <span style={{ backgroundImage: `url(${flightImg})`, backgroundSize: 'cover', backgroundPosition: 'center center', height:'100%', width:'100%', top:0, left:0, zIndex:-1, opacity:'1', position:'absolute' }}></span>
            </div>
            <div className='px-8 py-6'>
              <h2 className="text-4xl text-center font-bold text-slate-800">
              You’re almost there…
              </h2>
              <div className='text-xl text-center font-medium block mb-4'>Sign up for our waitlist to get the offers!</div>
              <div>
                <form onSubmit={handleSubmit} className="flex items-start">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:border-slate-500"
                  />
                  <button
                    type="submit"
                    className="w-auto bg-slate-800 border border-slate-500 rounded-r-lg text-white px-5 py-2 hover:slate-700 transition duration-300"
                  >
                    Submit
                  </button>
                </form>
              </div>
              {/* <p className='text-center mt-4 italic text-slate-600'>Thank you for joining the Valueflights.</p> */}
            </div>
          </div>
        </div>
      )}

      {isOpen && isSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center z-999" style={{backgroundColor:'rgba(0,0,0,0.6)'}}>
          <div className="bg-white rounded-lg overflow-hidden max-w-lg w-full relative">
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 text-white focus:outline-none z-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className='bg-slate-800 py-20 relative z-1'>
              <span style={{ backgroundImage: `url(${flightImg})`, backgroundSize: 'cover', backgroundPosition: 'center center', height:'100%', width:'100%', top:0, left:0, zIndex:-1, opacity:'1', position:'absolute' }}></span>
            </div>
            <div className='px-8 py-6'>
              <div className='w-full mb-4'>
                <FiCheckCircle className='size-16 mx-auto text-green-600'/>
              </div>
              <h2 className="text-4xl text-center font-bold text-slate-800 mb-3">Thank you for signing up!</h2>
              <div className='text-lg text-center text-slate-500 font-medium block mb-4'>You’re now on the waitlist to get the best offers.</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WaitlistModal;
