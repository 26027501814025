import React from "react";
import { Route, Routes } from "react-router-dom";
import './assets/css/tailwind.css'
import './assets/css/materialdesignicons.min.css'

import IndexThree from './pages/index/index-three'
import Aboutus from './pages/aboutus'
import Terms from './pages/utility/terms'
import Privacy from './pages/utility/privacy'
import Comingsoon from './pages/special/comingsoon'
import Maintenance from './pages/special/maintenance'
import Error from './pages/special/404'
import Contact from './pages/contact'

function App() {
  return (
   <Routes>
    <Route path="/" element={<IndexThree/>}/>
    <Route path="/aboutus" element={<Aboutus/>}/>
    <Route path="/terms" element={<Terms/>}/>
    <Route path="/privacy" element={<Privacy/>}/>
    <Route path="/comingsoon" element={<Comingsoon/>}/>
    <Route path="/maintenance" element={<Maintenance/>}/>
    <Route path="/404" element={<Error/>}/>
    <Route path="/contact" element={<Contact/>}/>
   </Routes>
  );
}

export default App;
