import React from "react";
import { Link } from "react-router-dom";

import about from '../assets/images/bg/1.jpg'
import about1 from '../assets/images/bg/5.jpg'
import about2 from '../assets/images/blog/4.jpg'
import map from '../assets/images/map-plane-big.png'

import {FiUsers,FiGlobe} from '../assets/icons/vander'

import CountUp from 'react-countup';

export default function About(){
    return(
        <div className="container relative md:mt-24 mb-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-start gap-6 relative">
                <div className="md:col-span-5">
                    <div className="relative pt-8">
                        <img src={about} className="mx-auto rounded-3xl shadow dark:shadow-gray-700 w-[90%]" alt=""/>                        
                        <img src={about1} className="absolute -mt-12 ms-12 rounded-3xl shadow dark:shadow-gray-700 h-[70%] w-[50%]" alt=""/>                        
                        <img src={about2} className="absolute mt-5 right-6 rounded-3xl shadow dark:shadow-gray-700 h-[50%] w-[30%]" alt=""/>                        
                    </div>
                </div>

                <div className="md:col-span-7">
                    <div className="lg:ms-8">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Push Your Limits – Book Your Next Adventure</h3>
                        <p className="text-slate-600 max-w-xl mb-6">Explore some of the world's most exhilarating destinations and activities. From skydiving over majestic landscapes to diving with sharks, these trips are for thrill-seekers.</p>

                        <h2 className="text-xl font-medium mb-2 text-slate-800">Adventure Packages:</h2>
                        <ul className="list-disc text-slate-600 ms-9">
                            <li>Everest Base Camp Trek + Skydive – From $2,000</li>
                            <li>Scuba Dive with Sharks in the Bahamas – From $1,500</li>
                            <li>Bungee Jumping in New Zealand – From $300</li>
                        </ul>
                        <div className="lg:mt-[35px]">
                            <input type="button" id="search-buy" name="search" className="py-1 px-5 h-10 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md cursor-pointer" defaultValue="Book Your Thrill"/>
                        </div>
                    </div>
                </div>

                <div className="absolute bottom-0 start-1/3 -z-1">
                    <img src={map} className="lg:w-[600px] w-96" alt=""/>
                </div>
            </div>
        </div>
    )
}